@use "/styles/mixins";
@use "/styles/colors.module";
@use "/styles/typography";

.section {
  background-color: colors.$global-primary-500;
}

.title {
  margin-top: 2.4rem;
  max-width: 312px;

  @include mixins.from-md-up {
    margin-top: 0;
    max-width: 437px;
  }
}

.description {
  @include typography.body-1;

  max-width: 312px;

  @include mixins.from-md-up {
    @include typography.body-2;

    max-width: 417px;
  }
}
