@use "/styles/mixins";
@use "/styles/typography";
@use "/styles/colors.module";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mixins.from-lg-up {
    gap: 40px;
  }

  .title {
    @include typography.body-1(true);

    @include mixins.from-lg-up {
      @include typography.header-5;

      gap: 16px;
    }

    @include mixins.from-lg-up {
      max-width: 60%;
    }
  }

  .highlights-container {
    @include typography.body-2;

    display: grid;
    gap: 12px;

    @include mixins.from-lg-up {
      gap: 16px;
      grid-template-columns: 1fr 1fr;
    }

    .highlight {
      align-items: center;
      display: flex;
      gap: 8px;

      .icon {
        color: colors.$global-primary-500;
      }
    }
  }
}
