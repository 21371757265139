@use "/styles/colors.module";
@use "/styles/typography";

.link {
  @include typography.body-1(true);

  width: max-content;
  display: flex;
  align-items: center;
  color: colors.$global-primary-500;
}

.icon {
  margin-left: 4px;
}
