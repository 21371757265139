$global-primary-100: #ccd6f1;
$global-primary-200: #99ace3;
$global-primary-300: #6683d5;
$global-primary-400: #3359c7;
$global-primary-500: #0030b9;
$global-primary-600: #002694;
$global-primary-700: #001d6f;
$global-primary-800: #00134a;
$global-primary-900: #000a25;
$global-secondary-100: #f2f5fb;
$global-secondary-200: #d4d8e0;
$global-secondary-300: #b6bac6;
$global-secondary-400: #979dab;
$global-secondary-500: #798090;
$global-secondary-600: #5b6275;
$global-secondary-700: #3d455b;
$global-secondary-800: #1e2740;
$global-secondary-900: #000a25;
$text-body: #000a25;
$text-body-light: #f8f9fa;
$text-muted: #798090;
$text-link: #0d6efd;
$text-promotional: #7fde45;
$feedback-success-100: #d1e7dd;
$feedback-success-200: #a3cfbb;
$feedback-success-300: #75b798;
$feedback-success-400: #479f76;
$feedback-success-500: #198754;
$feedback-success-600: #146c43;
$feedback-success-700: #0f5132;
$feedback-success-800: #0a3622;
$feedback-success-900: #051b11;
$feedback-info-100: #cfe5ef;
$feedback-info-200: #9fcbde;
$feedback-info-300: #70b0ce;
$feedback-info-400: #4096bd;
$feedback-info-500: #066bb3;
$feedback-info-600: #0d638a;
$feedback-info-700: #0a4a68;
$feedback-info-800: #063245;
$feedback-info-900: #031923;
$feedback-warning-100: #fbe1cc;
$feedback-warning-200: #f7c499;
$feedback-warning-300: #f4a666;
$feedback-warning-400: #f08933;
$feedback-warning-500: #ec6b00;
$feedback-warning-600: #bd5600;
$feedback-warning-700: #8e4000;
$feedback-warning-800: #5e2b00;
$feedback-warning-900: #2f1500;
$feedback-danger-100: #f8d7da;
$feedback-danger-200: #f1aeb5;
$feedback-danger-300: #ea868f;
$feedback-danger-400: #e35d6a;
$feedback-danger-500: #dc3545;
$feedback-danger-600: #b02a37;
$feedback-danger-700: #842029;
$feedback-danger-800: #58151c;
$feedback-danger-900: #2c0b0e;
$neutral-high-light: #f8f9fa;
$neutral-high-pure: #fff;
$neutral-low-dark: #111;
$neutral-low-pure: #000;
$disabled-global-primary-500: #a6b7e7;
$disabled-global-secondary-500: #d0d3d8;
$disabled-text-body: #a6a9b3;
$disabled-text-link: #a6b7e7;
$disabled-text-muted: #d0d3d8;
$disabled-feedback-danger-500: #f3b8be;
$disabled-feedback-info-500: #a8cbe4;
$disabled-feedback-success-500: #aed5c3;
$disabled-feedback-warning-500: #f8cba6;
$stroke-primary-dark: #00134a;
$stroke-primary-light: #99ace3;
$stroke-secondary-dark: #1e2740;
$stroke-secondary-light: #d4d8e0;
$overlay-dark-30: #0000004d;
$overlay-light-20: #fff3;
$overlay-light-50: #ffffff7f;

/* stylelint-disable max-line-length,order/properties-alphabetical-order,property-case,property-no-unknown,selector-pseudo-class-no-unknown */
:export {
  globalPrimary100: $global-primary-100;
  globalPrimary200: $global-primary-200;
  globalPrimary300: $global-primary-300;
  globalPrimary400: $global-primary-400;
  globalPrimary500: $global-primary-500;
  globalPrimary600: $global-primary-600;
  globalPrimary700: $global-primary-700;
  globalPrimary800: $global-primary-800;
  globalPrimary900: $global-primary-900;
  globalSecondary100: $global-secondary-100;
  globalSecondary200: $global-secondary-200;
  globalSecondary300: $global-secondary-300;
  globalSecondary400: $global-secondary-400;
  globalSecondary500: $global-secondary-500;
  globalSecondary600: $global-secondary-600;
  globalSecondary700: $global-secondary-700;
  globalSecondary800: $global-secondary-800;
  globalSecondary900: $global-secondary-900;
  textBody: $text-body;
  textBodyLight: $text-body-light;
  textLink: $text-link;
  textMuted: $text-muted;
  textPromotional: $text-promotional;
  feedbackSuccess100: $feedback-success-100;
  feedbackSuccess200: $feedback-success-200;
  feedbackSuccess300: $feedback-success-300;
  feedbackSuccess400: $feedback-success-400;
  feedbackSuccess500: $feedback-success-500;
  feedbackSuccess600: $feedback-success-600;
  feedbackSuccess700: $feedback-success-700;
  feedbackSuccess800: $feedback-success-800;
  feedbackSuccess900: $feedback-success-900;
  feedbackInfo100: $feedback-info-100;
  feedbackInfo200: $feedback-info-200;
  feedbackInfo300: $feedback-info-300;
  feedbackInfo400: $feedback-info-400;
  feedbackInfo500: $feedback-info-500;
  feedbackInfo600: $feedback-info-600;
  feedbackInfo700: $feedback-info-700;
  feedbackInfo800: $feedback-info-800;
  feedbackInfo900: $feedback-info-900;
  feedbackWarning100: $feedback-warning-100;
  feedbackWarning200: $feedback-warning-200;
  feedbackWarning300: $feedback-warning-300;
  feedbackWarning400: $feedback-warning-400;
  feedbackWarning500: $feedback-warning-500;
  feedbackWarning600: $feedback-warning-600;
  feedbackWarning700: $feedback-warning-700;
  feedbackWarning800: $feedback-warning-800;
  feedbackWarning900: $feedback-warning-900;
  feedbackDanger100: $feedback-danger-100;
  feedbackDanger200: $feedback-danger-200;
  feedbackDanger300: $feedback-danger-300;
  feedbackDanger400: $feedback-danger-400;
  feedbackDanger500: $feedback-danger-500;
  feedbackDanger600: $feedback-danger-600;
  feedbackDanger700: $feedback-danger-700;
  feedbackDanger800: $feedback-danger-800;
  feedbackDanger900: $feedback-danger-900;
  neutralHighLight: $neutral-high-light;
  neutralHighPure: $neutral-high-pure;
  neutralLowDark: $neutral-low-dark;
  neutralLowPure: $neutral-low-pure;
  disabledGlobalPrimary500: $disabled-global-primary-500;
  disabledGlobalSecondary500: $disabled-global-secondary-500;
  disabledTextBody: $disabled-text-body;
  disabledTextLink: $disabled-text-link;
  disabledTextMuted: $disabled-text-muted;
  disabledFeedbackDanger500: $disabled-feedback-danger-500;
  disabledFeedbackInfo500: $disabled-feedback-info-500;
  disabledFeedbackSuccess500: $disabled-feedback-success-500;
  disabledFeedbackWarning500: $disabled-feedback-warning-500;
  strokePrimaryDark: $stroke-primary-dark;
  strokePrimaryLight: $stroke-primary-light;
  strokeSecondaryDark: $stroke-secondary-dark;
  strokeSecondaryLight: $stroke-secondary-light;
  overlayDark30: $overlay-dark-30;
  overlayLight20: $overlay-light-20;
  overlayLight50: $overlay-light-50;
}

/* stylelint-enable */
