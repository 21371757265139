@use "/styles/colors.module";
@use "/styles/typography";

.button {
  @include typography.subtitle-2;

  align-items: center;
  background-color: colors.$global-primary-500;
  border: none;
  border-radius: 40px;
  color: colors.$neutral-high-pure;
  cursor: pointer;
  display: flex;
  height: 64px;
  justify-content: center;
  min-width: 232px;
  padding: 16px;
  transition: all 150ms ease;
  width: fit-content;

  &:hover {
    background-color: colors.$global-primary-700;
  }
}
