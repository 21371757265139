@use "mixins";

$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

@mixin set-typography-values($sizeXs, $sizeMd, $sizeLg, $weight) {
  font-size: $sizeXs;
  font-weight: $weight;

  @include mixins.from-md-up {
    font-size: $sizeMd;
  }

  @include mixins.from-lg-up {
    font-size: $sizeLg;
  }
}

@mixin header-1($isExtrabold: false) {
  @include set-typography-values(4.8rem, 5.6rem, 7.2rem, if($isExtrabold, $extrabold, $medium));
}

@mixin header-2 {
  @include set-typography-values(4rem, 4rem, 4.8rem, $medium);
}

@mixin header-3 {
  @include set-typography-values(3.2rem, 3.2rem, 4rem, $semibold);
}

@mixin header-4($isSemibold: false) {
  @include set-typography-values(2.4rem, 2.4rem, 3.2rem, $semibold);
}

@mixin header-5 {
  @include set-typography-values(2rem, 2rem, 2.4rem, $semibold);
}

@mixin subtitle-1($isSemibold: false) {
  @include set-typography-values(1.8rem, 1.8rem, 2rem, if($isSemibold, $semibold, $regular));
}

@mixin subtitle-2 {
  @include set-typography-values(1.8rem, 1.8rem, 2rem, $semibold);
}

@mixin body-1($isSemibold: false) {
  @include set-typography-values(1.6rem, 1.6rem, 1.8rem, if($isSemibold, $semibold, $regular));
}

@mixin body-2($isSemibold: false) {
  @include set-typography-values(1.4rem, 1.4rem, 1.6rem, if($isSemibold, $semibold, $regular));
}

@mixin caption-1($isSemibold: false) {
  @include set-typography-values(1.2rem, 1.2rem, 1.4rem, if($isSemibold, $semibold, $regular));
}

@mixin caption-2() {
  @include set-typography-values(1rem, 1rem, 1.2rem, $regular);
}
