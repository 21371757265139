@use "/styles/mixins";
@use "/styles/typography";

.benefits-container {
  margin: 32px 0;
}

.benefit-item {
  align-items: baseline;
  display: flex;

  @include typography.body-2;

  & + .benefit-item {
    margin-top: 4px;
  }
}

.benefit-highlight {
  @include typography.header-5;

  color: #7fde45;
}

.check-icon {
  margin-right: 8px;

  @include mixins.from-lg-up {
    margin-right: 8px;
  }
}
