@use "/styles/colors.module";
@use "/styles/typography";
@use "/styles/mixins";

.header-container {
  align-items: center;
  display: flex;
  height: 75px;
  justify-content: space-between;
  padding: 2.5vh 4.7vw;
  position: sticky;
  top: 0;
  transition: background-color 0.3s ease-in;
  width: 100vw;
  z-index: 9999;

  @include mixins.from-lg-up {
    height: 100px;
  }

  .menu {
    @include typography.body-2(true);

    align-items: center;
    display: flex;
    flex: 1;
    gap: 24px;

    .nav-link {
      @include typography.body-2(true);

      color: colors.$neutral-high-pure;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .nav-buttons {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .onboarding-button {
      height: auto;
      padding: 14px 24px;
    }
  }

  &.alt-color {
    background-color: colors.$neutral-high-pure;
  }

  &.alt-color,
  &.light-color {
    .menu {
      .nav-link {
        color: colors.$neutral-low-dark;
        opacity: 1;

        &:hover {
          color: colors.$global-primary-500;
        }
      }
    }
  }
}

.asaas-logo {
  align-self: baseline;
  margin-right: 40px;

  &.center {
    margin: 0 auto;
  }
}

.login-link {
  margin-right: 24px;
}
