@function getSizes() {
  $sizes: "xs", "sm", "md", "lg", "xl", "xxl";
  @return $sizes;
}

@function getMidScreenSizes() {
  $sizes: "md", "lg";
  @return $sizes;
}

@function getBigScreenSizes() {
  $sizes: "xl", "xxl";
  @return $sizes;
}

@mixin getResponsiveMedia($size) {
  @if $size == "xs" {
    @media screen and (max-width: 360px) {
      @content;
    }
  } @else if $size == "sm" {
    @media screen and (min-width: 576px) {
      @content;
    }
  } @else if $size == "md" {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $size == "lg" {
    @media screen and (min-width: 992px) {
      @content;
    }
  } @else if $size == "xl" {
    @media screen and (min-width: 1200px) {
      @content;
    }
  } @else if $size == "xxl" {
    @media screen and (min-width: 1400px) {
      @content;
    }
  }
}

@mixin for-xs-only {
  @media screen and (max-width: 360px) {
    @content;
  }
}

@mixin from-sm-up {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin from-md-up {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin from-lg-up {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin from-xl-up {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin from-xxl-up {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
