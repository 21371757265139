@use "/styles/mixins";
@use "/styles/colors.module";
@use "/styles/typography";

.container {
  background-color: colors.$neutral-high-pure;
  border-radius: 21px 21px 0;
  display: grid;
  gap: 0.8rem;
  margin-bottom: 2.4rem;
  padding: 2.4rem;

  @include mixins.from-md-up {
    margin-bottom: 0;
  }
}

.title {
  @include typography.subtitle-1(true);

  color: colors.$neutral-low-dark;
}

.input-container {
  display: grid;
  gap: 1.6rem;
  grid-template-columns: 1.5fr 1fr;
}

.input {
  background-color: transparent;
  border: 1px solid colors.$global-secondary-400;
  border-radius: 8px;
  color: colors.$text-body;
  padding: 1.8rem 1.6rem;
}

.terms {
  @include typography.caption-2;

  color: colors.$text-body;
}

.link {
  text-decoration: underline;
}

.button {
  font-weight: typography.$bold !important;
  margin-top: 0.8rem;
}

.button-error {
  pointer-events: none;
}

.error {
  @include typography.caption-1;

  color: colors.$feedback-danger-500;
}

.error-border {
  border-color: colors.$feedback-danger-500;
}
