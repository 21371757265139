@use '/styles/colors.module';
@use '/styles/typography';

.icon-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  &.column {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
}

.text {
  @include typography.body-2;

  text-align: left;

  &.center {
    text-align: center
  }

  &.bold {
    @include typography.subtitle-1(true);
  }
}

.icon {
  color: colors.$global-primary-500;
  padding-right: 8px;
}
