@use "/styles/colors.module";
@use "/styles/mixins";
@use "/styles/typography";

.terms {
  @include typography.caption-2;

  color: colors.$text-body;
  margin-top: 0.8rem;
}

.secondary {
  color: colors.$global-secondary-700;

  @include mixins.from-md-up {
    color: colors.$neutral-high-pure;
  }
}

.dark {
  color: colors.$global-secondary-700;
}

.link {
  color: colors.$global-primary-500;
  text-decoration: underline;
}

.link-secondary {
  color: colors.$global-secondary-700;

  @include mixins.from-md-up {
    &:not(.dark) {
      color: colors.$neutral-high-pure;
    }
  }
}
