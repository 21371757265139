@use "/styles/colors.module";

.mobile-menu {
  background-color: colors.$neutral-high-pure;
  display: flex;
  flex-direction: column;
  height: 100vh;
  opacity: 0;
  padding: 12.5vh 4.4vw 3.125vh;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: 99;

  &.menu-open {
    opacity: 1;
    transition: opacity 0.3s ease;
    visibility: visible;
  }

  .nav-buttons {
    display: grid;
    gap: 12px;
    margin-top: 50px;
  }

  .onboarding-button {
    width: auto;
  }
}
