@use "/styles/colors.module";
@use "/styles/mixins";

.container {
  margin: 2rem 0 10rem;
  width: 80%;

  @include mixins.from-md-up {
    width: 35vw;
  }
}

.input-container {
  display: grid;

  @include mixins.from-md-up {
    gap: 1.5rem;
    grid-template-columns: 1.5fr 1fr;
  }
}

.title-light {
  color: colors.$neutral-high-pure;
}

.button {
  margin: 0.8rem auto 0;
}

.button-error {
  pointer-events: none;
}
