@use "/styles/colors.module";

.slides-control-next {
  right: -38px;
}

.slides-control-previous {
  left: -38px;
}

.slides-control-next,
.slides-control-previous {
  color: colors.$neutral-high-pure;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  &:hover {
    color: colors.$global-secondary-300;
    transition: 0.2s;
  }

  &::before {
    content: none;
  }
}

.slides-control-disabled {
  color: colors.$global-secondary-300;
}
