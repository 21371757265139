@use "/styles/colors.module";
@use "/styles/mixins";
@use "/styles/typography";

.container {
  background-color: colors.$global-secondary-100;
  border-radius: 16px;
  margin: 2rem 0;
  padding: 1.6rem;

  @include mixins.from-md-up {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }
}

.input-container {
  display: flex;
  flex-direction: column;

  @include mixins.from-md-up {
    flex-direction: row;
  }
}

.input {
  border: 1px solid colors.$stroke-secondary-light;
  border-radius: 8px;
  color: colors.$text-body;
  padding: 1.8rem 1.6rem;

  &::placeholder {
    color: colors.$global-secondary-700;
  }

  @include mixins.from-md-up {
    border-radius: 36px 0 0 36px;
    padding: 1.2rem 1.8rem;
    width: fit-content;
  }
}

.button {
  margin-top: 1.6rem;
  width: 100%;

  @include mixins.from-md-up {
    border-radius: 0 36px 36px 0;
    margin-top: 0;
  }
}

.button-error {
  pointer-events: none;
}

.error {
  @include typography.caption-1;

  color: colors.$feedback-danger-500;
}

.error-border {
  border-color: colors.$feedback-danger-500;
}
