@use "/styles/typography";
@use "/styles/mixins";

.breadcrumb {
  @include typography.body-2;

  @include mixins.from-sm-up {
    @include typography.caption-1;
  }
}
