@use "/styles/colors.module";
@use "/styles/mixins";
@use "/styles/typography";

.container {
  background-color: colors.$neutral-high-pure;
  border-radius: 21px 21px 0;
  margin: 2rem 0;
  padding: 2.4rem 2.4rem 1.6rem;
  position: relative;
  width: 87vw;

  &::before {
    background-color: colors.$overlay-light-50;
    border-radius: 21px 21px 0;
    content: "";
    inset: 0;
    position: absolute;
    transform: translateX(0.8rem) translateY(-1rem);
    z-index: -1;
  }

  &::after {
    background-color: colors.$overlay-light-20;
    border-radius: 21px 21px 0;
    content: "";
    inset: 0;
    position: absolute;
    transform: translateX(1.6rem) translateY(-2rem);
    z-index: -1;
  }

  @include mixins.from-md-up {
    width: 80%;
  }
}

.title {
  @include typography.header-5;

  color: colors.$global-primary-500;
}

.label {
  @include typography.body-2(true);

  color: colors.$text-body;
  display: block;
  margin-top: 0.8rem;
}

.input {
  background: colors.$neutral-high-pure;
  border: 1px solid colors.$global-secondary-300;
  border-radius: 8px;
  color: colors.$text-body;
  margin-top: 0.4rem;
  padding: 1.8rem 1.6rem;
  width: 100%;
}

.button {
  margin-top: 0.8rem;
}

.button-error {
  pointer-events: none;
}

.error {
  @include typography.caption-1;

  color: colors.$feedback-danger-500;
}

.error-border {
  border-color: colors.$feedback-danger-500;
}
