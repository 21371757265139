@use "/styles/colors.module";

.link {
  text-decoration: underline;

  &.primary {
    color: colors.$global-primary-500;
  }

  &.dark {
    color: colors.$neutral-low-dark;
  }
}
