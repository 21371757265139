@use "/styles/colors.module";
@use "/styles/typography";
@use "/styles/mixins";

.breadcrumb {
  margin: 0 auto 24px;
}

.main-section-extra-classes {
  margin-top: -75px;

  @include mixins.from-lg-up {
    margin-top: -100px;
  }
}

.main-section {
  align-content: center;
  align-items: center;
  color: colors.$neutral-high-pure;
  display: grid;
  height: 100vh;
  padding: 0 4.5vw;
}

.information-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.image-container {
  height: 100%;
  position: relative;
}

.centralized-bg {
  height: 100%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.centralized-bg-image {
  height: auto;
  max-width: 100%;

  @include mixins.from-sm-up {
    height: 100%;
    max-width: none;
    width: auto;
  }
}

.mobile-centralized-bg-image {
  margin-bottom: 10vh;
  padding: 4.5vw 4.5vw 0;
}

.title {
  @include typography.header-4;
}

.description {
  @include typography.body-2;

  margin-bottom: 32px;
  margin-top: 8px;
}

.onboarding-button {
  min-width: unset;
}

@include mixins.from-sm-up {
  .breadcrumb {
    margin-left: 0;
    margin-right: 0;
  }

  .description {
    @include typography.body-1;

    margin: 24px 0;
  }

  .main-section {
    grid-template-columns: 1fr 2fr;
    padding-right: 0;
  }

  .information-container {
    align-items: flex-start;
    display: flex;
    height: 90vh;
    justify-content: center;
    text-align: left;
  }
}

.logo-image {
  margin-bottom: 8px;

  @include mixins.from-lg-up {
    margin-bottom: 24px;
  }
}
